import React, {
  ChangeEvent,
  ChangeEventHandler,
  FormEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import ApplicationDescription from './description/ApplicationDescription';
import { useConference } from '../common/ConferenceProvider';
import { Applicant, Conference, ConferenceState } from '../common/types';
import { eventDate } from '../../utils/eventDate';
import { ApplicationForm } from './form/ApplicationForm';
import { useApplicants } from '../common/ApplicantsProvider';
import { v4 as uuid } from 'uuid';
import { MESSAGES, MessageType } from './form/applicationMessages';

import './Application.scss';
import { toggleArrayValue } from '../../utils/toggleArrayValue';
import { ExclusionAnnouncement } from './ExclusionAnnouncement';
import { Message } from './Message';
import {
  isValidEmail,
  isValidNickname,
  validateRoomTypesSelected,
} from '../../utils/validation';

enum FormState {
  LOADING,
  READY,
  SUBMITTING,
  SUBMITTED,
}

const VALIDATED_FIELDS: string[] = [
  'email',
  'name',
  'roomTypesSelected',
  'privacyStatementAcknowledged',
];

const initApplicant = () => ({
  id: uuid(),
  conferenceId: '',
  name: '',
  email: '',
  roomTypeSelected: [],
  diversitySelected: '',
});

export default function Application() {
  const conf = useConference();
  const applicants = useApplicants();

  const [conference, setConference] = useState<Conference | undefined>();
  const [formState, setFormState] = useState(FormState.LOADING);
  const [isSubmitBtnEnabled, setSubmitBtnEnabled] = useState(false);
  const [messageType, setMessageType] = useState(MessageType.EMPTY);

  const [validated, setValidated] = useState<Record<string, boolean>>({});
  const [applicant, setApplicant] = useState<Applicant>(initApplicant());

  const checkComplete = useCallback(() => {
    const valid =
      VALIDATED_FIELDS.reduce((all, e) => all && validated[e], true) &&
      formState === FormState.READY;
    setSubmitBtnEnabled(valid ?? false);
  }, [validated, formState]);

  useEffect(() => {
    if (conference === undefined) {
      conf.conference().then((con) => {
        setConference(con);
        setApplicant({ ...applicant, conferenceId: con?.id ?? '' });
      });
    } else if (formState === FormState.LOADING) {
      setFormState(FormState.READY);
    } else {
      if (
        [
          ConferenceState.PREPARATION,
          ConferenceState.CLEANUP,
          ConferenceState.CONCLUDED,
        ].some((s) => s === conference.state)
      )
        setMessageType(MessageType.REGISTRATION_CLOSED);
      checkComplete();
    }
  }, [checkComplete, conf, conference, formState, applicant, validated]);

  const onNicknameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name: string = event.target.value;
    setApplicant({ ...applicant, name });
    setValidated({ ...validated, name: isValidNickname(name) });
  };
  const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const email: string = event.target.value;
    setApplicant({ ...applicant, email });
    setValidated({ ...validated, email: isValidEmail(email) });
  };

  const onRoomTypeChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const roomTypeSelected = toggleArrayValue(
      applicant.roomTypeSelected,
      event.target.value,
    );
    const updated: Applicant = { ...applicant, roomTypeSelected };
    setApplicant(updated);
    setValidated({
      ...validated,
      roomTypesSelected: validateRoomTypesSelected(updated),
    });
  };

  const onRoommateChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const updated: Applicant = { ...applicant, roommate: event.target.value };
    setApplicant(updated);
    setValidated({
      ...validated,
      roomTypesSelected: validateRoomTypesSelected(updated),
    });
  };

  const onDiversityChange = (value: string) => {
    setApplicant({ ...applicant, diversitySelected: value });
  };

  const onFinancialAidChange = (value: boolean) => {
    setApplicant({ ...applicant, requestedFinancialAid: value });
  };

  const onPrivacyStatementChange: ChangeEventHandler<HTMLInputElement> = async (
    event,
  ) => {
    setValidated({
      ...validated,
      privacyStatementAcknowledged: event.target.checked,
    });
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    setFormState(FormState.SUBMITTING);
    await applicants
      .addApplicant(applicant)
      .then(() => {
        setMessageType(MessageType.SUCCESS);
      })
      .catch((e) => {
        if (e.type === 'AlreadyRegisteredException') {
          setMessageType(MessageType.ALREADY_APPLIED);
        } else if (e.type === 'BadRequestException') {
          setMessageType(MessageType.BAD_REQUEST);
        } else {
          setMessageType(MessageType.FAIL);
        }
      });
    setFormState(FormState.SUBMITTED);
  };

  const roomTypeOptions = conference?.roomTypes;
  const isLoading: boolean = formState === FormState.LOADING;
  return formState === FormState.SUBMITTED ||
    messageType === MessageType.REGISTRATION_CLOSED ? (
    <Message messageType={messageType} messageHtml={MESSAGES[messageType]} />
  ) : (
    <div>
      <div
        className={`row mt-5 justify-content-center ${isLoading ? 'd-flex' : 'd-none'}`}
      >
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      <div
        id="application"
        className={`application container ${isLoading ? 'invisible' : 'visible'}`}
      >
        <div className={'row'}>
          <div className="col-md-12">
            <div className="page-header">
              <div className="btn-group pull-right hidden-print" />
              <h1>Apply for a Ticket!</h1>
              <h2>{`${conference?.title}, ${eventDate(conference?.startDate, conference?.endDate)}`}</h2>
            </div>
          </div>
        </div>
        <ExclusionAnnouncement />
        <ApplicationForm
          roomTypeOptions={roomTypeOptions}
          isSubmitBtnEnabled={isSubmitBtnEnabled}
          handlers={{
            onSubmit,
            onNicknameChange,
            onDiversityChange,
            onFinancialAidChange,
            onEmailChange,
            onRoomTypeChange,
            onRoommateChange,
            onPrivacyStatementChange,
          }}
          values={applicant}
          validated={validated}
        />
        <ApplicationDescription />
      </div>
    </div>
  );
}
