export type UUID = string;
export type Identifier = string;
export type Name = string;
export type URL = string;
export type Description = string;

export type Iterable = { [key: string]: any };

export type TableRow = { index?: number };

export type Entity = {
  id: UUID;
};

export type RoomType = Iterable & {
  type: Identifier;
  description: string;
  pricePerNight: string;
  beds: number;
  count: number;
};
export type FlatFee = Iterable & {
  type: Identifier;
  description: string;
  price: string;
  excludeFromSponsoring?: boolean;
};

export enum ConferenceState {
  PREPARATION = 'PREPARATION',
  REGISTRATION = 'REGISTRATION',
  IN_PROGRESS = 'IN_PROGRESS',
  CLEANUP = 'CLEANUP',
  CONCLUDED = 'CONCLUDED',
}

export type Conference = Iterable &
  TableRow &
  Entity & {
    byline?: string;
    diversityRatio?: number;
    endDate?: string;
    flatFees?: FlatFee[];
    location: string;
    maxSeatsPerSponsor?: number;
    roomTypes?: RoomType[];
    startDate?: string;
    state?: ConferenceState;
    title: string;
    year: number;
  };

export type Applicant = TableRow &
  Entity & {
    conferenceId: UUID;
    email: string;
    name: string;
    roomTypeSelected: string[];
    roommate?: string;
    diversitySelected: string;
    requestedFinancialAid?: boolean;
  };
