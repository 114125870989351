import React from 'react';

export default function Imprint() {
  return (
    <div id="privacy_policy" className="container">
      <div id="privacy_policy_de">
        <h1>Datenschutz&shy;erkl&auml;rung</h1>
        <h4>1. Datenschutz auf einen Blick</h4>
        <h5>Allgemeine Hinweise</h5>{' '}
        <p>
          Die folgenden Hinweise geben einen einfachen &Uuml;berblick
          dar&uuml;ber, was mit Ihren personenbezogenen Daten passiert, wenn Sie
          diese Website besuchen. Personenbezogene Daten sind alle Daten, mit
          denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.
          Ausf&uuml;hrliche Informationen zum Thema Datenschutz entnehmen Sie
          unserer unter diesem Text aufgef&uuml;hrten Datenschutzerkl&auml;rung.
        </p>
        <h5>Datenerfassung auf dieser Website</h5>{' '}
        <h4>
          Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser Website?
        </h4>{' '}
        <p>
          Die Datenverarbeitung auf dieser Website erfolgt durch den
          Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem Abschnitt
          &bdquo;Hinweis zur Verantwortlichen Stelle&ldquo; in dieser
          Datenschutzerkl&auml;rung entnehmen.
        </p>{' '}
        <h4>Wie erfassen wir Ihre Daten?</h4>{' '}
        <p>
          Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
          mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die Sie
          in ein Kontaktformular eingeben.
        </p>{' '}
        <p>
          Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
          Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem
          technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem oder
          Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
          automatisch, sobald Sie diese Website betreten.
        </p>{' '}
        <h4>Wof&uuml;r nutzen wir Ihre Daten?</h4>{' '}
        <p>
          Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
          der Website zu gew&auml;hrleisten. Andere Daten k&ouml;nnen zur
          Analyse Ihres Nutzerverhaltens verwendet werden.
        </p>{' '}
        <h4>Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</h4>{' '}
        <p>
          Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber
          Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten
          personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem ein
          Recht, die Berichtigung oder L&ouml;schung dieser Daten zu verlangen.
          Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
          k&ouml;nnen Sie diese Einwilligung jederzeit f&uuml;r die Zukunft
          widerrufen. Au&szlig;erdem haben Sie das Recht, unter bestimmten
          Umst&auml;nden die Einschr&auml;nkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
          Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde zu.
        </p>{' '}
        <p>
          Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie
          sich jederzeit an uns wenden.
        </p>
        <h5>Analyse-Tools und Tools von Dritt&shy;anbietern</h5>{' '}
        <p>
          Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
          ausgewertet werden. Das geschieht vor allem mit sogenannten
          Analyseprogrammen.
        </p>{' '}
        <p>
          Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in
          der folgenden Datenschutzerkl&auml;rung.
        </p>
        <h4>2. Hosting und Content Delivery Networks (CDN)</h4>
        <p>Wir hosten die Inhalte unserer Website bei folgendem Anbieter:</p>
        <h5>Amazon Web Services (AWS)</h5>{' '}
        <p>
          Anbieter ist die Amazon Web Services EMEA SARL, 38 Avenue John F.
          Kennedy, 1855 Luxemburg (nachfolgend AWS).
        </p>{' '}
        <p>
          Wenn Sie unsere Website besuchen, werden Ihre personenbezogenen Daten
          auf den Servern von AWS verarbeitet. Hierbei k&ouml;nnen auch
          personenbezogene Daten an das Mutterunternehmen von AWS in die USA
          &uuml;bermittelt werden. Die Daten&uuml;bertragung in die USA wird auf
          die EU-Standardvertragsklauseln gest&uuml;tzt. Details finden Sie
          hier:{' '}
          <a
            href="https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/
          </a>
          .
        </p>{' '}
        <p>
          Weitere Informationen entnehmen Sie der Datenschutzerkl&auml;rung von
          AWS:{' '}
          <a
            href="https://aws.amazon.com/de/privacy/?nc1=f_pr"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://aws.amazon.com/de/privacy/?nc1=f_pr
          </a>
          .
        </p>{' '}
        <p>
          Die Verwendung von AWS erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
          DSGVO. Wir haben ein berechtigtes Interesse an einer m&ouml;glichst
          zuverl&auml;ssigen Darstellung unserer Website. Sofern eine
          entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
          ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und
          &sect; 25 Abs. 1 TDDDG, soweit die Einwilligung die Speicherung von
          Cookies oder den Zugriff auf Informationen im Endger&auml;t des
          Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TDDDG umfasst.
          Die Einwilligung ist jederzeit widerrufbar.
        </p>
        <p>
          Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach dem
          &bdquo;EU-US Data Privacy Framework&ldquo; (DPF). Der DPF ist ein
          &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und den USA,
          der die Einhaltung europ&auml;ischer Datenschutzstandards bei
          Datenverarbeitungen in den USA gew&auml;hrleisten soll. Jedes nach dem
          DPF zertifizierte Unternehmen verpflichtet sich, diese
          Datenschutzstandards einzuhalten. Weitere Informationen hierzu
          erhalten Sie vom Anbieter unter folgendem Link:{' '}
          <a
            href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active
          </a>
          .
        </p>
        <h4>Auftragsverarbeitung</h4>{' '}
        <p>
          Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur
          Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es
          sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
          gew&auml;hrleistet, dass dieser die personenbezogenen Daten unserer
          Websitebesucher nur nach unseren Weisungen und unter Einhaltung der
          DSGVO verarbeitet.
        </p>
        <h5>Amazon CloudFront CDN</h5>{' '}
        <p>
          Wir nutzen das Content Delivery Network Amazon CloudFront CDN.
          Anbieter ist die Amazon Web Services EMEA SARL, 38 avenue John F.
          Kennedy, L-1855, Luxemburg (nachfolgend &bdquo;Amazon&ldquo;).
        </p>{' '}
        <p>
          Bei Amazon CloudFront CDN handelt es sich um ein weltweit verteiltes
          Content Delivery Network. Dabei wird technisch der
          Informationstransfer zwischen Ihrem Browser und unserer Website
          &uuml;ber das Content Delivery Network geleitet. Hierdurch k&ouml;nnen
          wir die weltweite Erreichbarkeit und die Leistungsf&auml;higkeit
          unserer Website erh&ouml;hen.
        </p>{' '}
        <p>
          Der Einsatz von Amazon CloudFront CDN beruht auf unserem berechtigten
          Interesse an einer m&ouml;glichst fehlerfreien und sicheren
          Bereitstellung unseres Webangebotes (Art. 6 Abs. 1 lit. f DSGVO).
        </p>{' '}
        <p>
          Die Daten&uuml;bertragung in die USA wird auf die
          Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details
          finden Sie hier:{' '}
          <a
            href="https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/
          </a>
          .
        </p>{' '}
        <p>
          Weitere Informationen zu Amazon CloudFront CDN finden Sie hier:{' '}
          <a
            href="https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice__German_Translation.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice__German_Translation.pdf
          </a>
          .
        </p>
        <p>
          Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach dem
          &bdquo;EU-US Data Privacy Framework&ldquo; (DPF). Der DPF ist ein
          &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und den USA,
          der die Einhaltung europ&auml;ischer Datenschutzstandards bei
          Datenverarbeitungen in den USA gew&auml;hrleisten soll. Jedes nach dem
          DPF zertifizierte Unternehmen verpflichtet sich, diese
          Datenschutzstandards einzuhalten. Weitere Informationen hierzu
          erhalten Sie vom Anbieter unter folgendem Link:{' '}
          <a
            href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active
          </a>
          .
        </p>
        <h4>3. Allgemeine Hinweise und Pflicht&shy;informationen</h4>
        <h5>Datenschutz</h5>{' '}
        <p>
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen
          Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
          vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften
          sowie dieser Datenschutzerkl&auml;rung.
        </p>{' '}
        <p>
          Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
          Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
          pers&ouml;nlich identifiziert werden k&ouml;nnen. Die vorliegende
          Datenschutzerkl&auml;rung erl&auml;utert, welche Daten wir erheben und
          wof&uuml;r wir sie nutzen. Sie erl&auml;utert auch, wie und zu welchem
          Zweck das geschieht.
        </p>{' '}
        <p>
          Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet
          (z.&nbsp;B. bei der Kommunikation per E-Mail) Sicherheitsl&uuml;cken
          aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff
          durch Dritte ist nicht m&ouml;glich.
        </p>
        <h5>Hinweis zur verantwortlichen Stelle</h5>{' '}
        <p>
          Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser
          Website ist:
        </p>{' '}
        <p>
          SoCraTes Deutschland e.V.
          <br />
          Am Hirschanger 1<br />
          90610 Winkelhaid
        </p>
        <p>
          Telefon: 09187 7061771
          <br />
          E-Mail: info@socrates-conference.de
        </p>
        <p>
          Verantwortliche Stelle ist die nat&uuml;rliche oder juristische
          Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und
          Mittel der Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen,
          E-Mail-Adressen o. &Auml;.) entscheidet.
        </p>
        <h5>Speicherdauer</h5>{' '}
        <p>
          Soweit innerhalb dieser Datenschutzerkl&auml;rung keine speziellere
          Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
          bei uns, bis der Zweck f&uuml;r die Datenverarbeitung entf&auml;llt.
          Wenn Sie ein berechtigtes L&ouml;schersuchen geltend machen oder eine
          Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten
          gel&ouml;scht, sofern wir keine anderen rechtlich zul&auml;ssigen
          Gr&uuml;nde f&uuml;r die Speicherung Ihrer personenbezogenen Daten
          haben (z.&nbsp;B. steuer- oder handelsrechtliche
          Aufbewahrungsfristen); im letztgenannten Fall erfolgt die
          L&ouml;schung nach Fortfall dieser Gr&uuml;nde.
        </p>
        <h5>
          Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf
          dieser Website
        </h5>{' '}
        <p>
          Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten
          wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit.
          a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere
          Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle
          einer ausdr&uuml;cklichen Einwilligung in die &Uuml;bertragung
          personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung
          au&szlig;erdem auf Grundlage von Art. 49 Abs. 1 lit. a DSGVO. Sofern
          Sie in die Speicherung von Cookies oder in den Zugriff auf
          Informationen in Ihr Endger&auml;t (z.&nbsp;B. via
          Device-Fingerprinting) eingewilligt haben, erfolgt die
          Datenverarbeitung zus&auml;tzlich auf Grundlage von &sect; 25 Abs. 1
          TDDDG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur
          Vertragserf&uuml;llung oder zur Durchf&uuml;hrung vorvertraglicher
          Ma&szlig;nahmen erforderlich, verarbeiten wir Ihre Daten auf Grundlage
          des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre
          Daten, sofern diese zur Erf&uuml;llung einer rechtlichen Verpflichtung
          erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die
          Datenverarbeitung kann ferner auf Grundlage unseres berechtigten
          Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. &Uuml;ber die
          jeweils im Einzelfall einschl&auml;gigen Rechtsgrundlagen wird in den
          folgenden Abs&auml;tzen dieser Datenschutzerkl&auml;rung informiert.
        </p>
        <h5>Empfänger von personenbezogenen Daten</h5>{' '}
        <p>
          Im Rahmen unserer Gesch&auml;ftst&auml;tigkeit arbeiten wir mit
          verschiedenen externen Stellen zusammen. Dabei ist teilweise auch eine
          &Uuml;bermittlung von personenbezogenen Daten an diese externen
          Stellen erforderlich. Wir geben personenbezogene Daten nur dann an
          externe Stellen weiter, wenn dies im Rahmen einer
          Vertragserf&uuml;llung erforderlich ist, wenn wir gesetzlich hierzu
          verpflichtet sind (z.&nbsp;B. Weitergabe von Daten an
          Steuerbeh&ouml;rden), wenn wir ein berechtigtes Interesse nach Art. 6
          Abs. 1 lit. f DSGVO an der Weitergabe haben oder wenn eine sonstige
          Rechtsgrundlage die Datenweitergabe erlaubt. Beim Einsatz von
          Auftragsverarbeitern geben wir personenbezogene Daten unserer Kunden
          nur auf Grundlage eines g&uuml;ltigen Vertrags &uuml;ber
          Auftragsverarbeitung weiter. Im Falle einer gemeinsamen Verarbeitung
          wird ein Vertrag &uuml;ber gemeinsame Verarbeitung geschlossen.
        </p>
        <h5>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h5>{' '}
        <p>
          Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer
          ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie k&ouml;nnen eine
          bereits erteilte Einwilligung jederzeit widerrufen. Die
          Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten
          Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.
        </p>
        <h5>
          Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen
          sowie gegen Direktwerbung (Art. 21 DSGVO)
        </h5>{' '}
        <p>
          WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER
          F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GR&Uuml;NDEN, DIE
          SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG
          IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH
          F&Uuml;R EIN AUF DIESE BESTIMMUNGEN GEST&Uuml;TZTES PROFILING. DIE
          JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
          ENTNEHMEN SIE DIESER DATENSCHUTZERKL&Auml;RUNG. WENN SIE WIDERSPRUCH
          EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT
          MEHR VERARBEITEN, ES SEI DENN, WIR K&Ouml;NNEN ZWINGENDE
          SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG NACHWEISEN,
          DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN &Uuml;BERWIEGEN ODER DIE
          VERARBEITUNG DIENT DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER VERTEIDIGUNG
          VON RECHTSANSPR&Uuml;CHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
        </p>{' '}
        <p>
          WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
          BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
          VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
          DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH F&Uuml;R DAS PROFILING,
          SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
          WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT
          MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21
          ABS. 2 DSGVO).
        </p>
        <h5>
          Beschwerde&shy;recht bei der zust&auml;ndigen
          Aufsichts&shy;beh&ouml;rde
        </h5>{' '}
        <p>
          Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den
          Betroffenen ein Beschwerderecht bei einer Aufsichtsbeh&ouml;rde,
          insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen Aufenthalts,
          ihres Arbeitsplatzes oder des Orts des mutma&szlig;lichen
          Versto&szlig;es zu. Das Beschwerderecht besteht unbeschadet
          anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
        </p>
        <h5>Recht auf Daten&shy;&uuml;bertrag&shy;barkeit</h5>{' '}
        <p>
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
          oder in Erf&uuml;llung eines Vertrags automatisiert verarbeiten, an
          sich oder an einen Dritten in einem g&auml;ngigen, maschinenlesbaren
          Format aush&auml;ndigen zu lassen. Sofern Sie die direkte
          &Uuml;bertragung der Daten an einen anderen Verantwortlichen
          verlangen, erfolgt dies nur, soweit es technisch machbar ist.
        </p>
        <h5>Auskunft, Berichtigung und L&ouml;schung</h5>{' '}
        <p>
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
          das Recht auf unentgeltliche Auskunft &uuml;ber Ihre gespeicherten
          personenbezogenen Daten, deren Herkunft und Empf&auml;nger und den
          Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
          L&ouml;schung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
          personenbezogene Daten k&ouml;nnen Sie sich jederzeit an uns wenden.
        </p>
        <h5>Recht auf Einschr&auml;nkung der Verarbeitung</h5>{' '}
        <p>
          Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Hierzu k&ouml;nnen Sie sich
          jederzeit an uns wenden. Das Recht auf Einschr&auml;nkung der
          Verarbeitung besteht in folgenden F&auml;llen:
        </p>
        <ul>
          <li>
            Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
            personenbezogenen Daten bestreiten, ben&ouml;tigen wir in der Regel
            Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer der
            Pr&uuml;fung haben Sie das Recht, die Einschr&auml;nkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </li>
          <li>
            Wenn die Verarbeitung Ihrer personenbezogenen Daten
            unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen Sie statt der
            L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung
            verlangen.
          </li>
          <li>
            Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie
            sie jedoch zur Aus&uuml;bung, Verteidigung oder Geltendmachung von
            Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht, statt der
            L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen.
          </li>
          <li>
            Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
            haben, muss eine Abw&auml;gung zwischen Ihren und unseren Interessen
            vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
            &uuml;berwiegen, haben Sie das Recht, die Einschr&auml;nkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </li>
        </ul>
        <p>
          Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
          eingeschr&auml;nkt haben, d&uuml;rfen diese Daten &ndash; von ihrer
          Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung oder zur
          Geltendmachung, Aus&uuml;bung oder Verteidigung von
          Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen
          nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden eines
          wichtigen &ouml;ffentlichen Interesses der Europ&auml;ischen Union
          oder eines Mitgliedstaats verarbeitet werden.
        </p>
        <h5>SSL- bzw. TLS-Verschl&uuml;sselung</h5>{' '}
        <p>
          Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der
          &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
          oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-
          bzw. TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung
          erkennen Sie daran, dass die Adresszeile des Browsers von
          &bdquo;http://&ldquo; auf &bdquo;https://&ldquo; wechselt und an dem
          Schloss-Symbol in Ihrer Browserzeile.
        </p>{' '}
        <p>
          Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist, k&ouml;nnen
          die Daten, die Sie an uns &uuml;bermitteln, nicht von Dritten
          mitgelesen werden.
        </p>
        <h5>Verschl&uuml;sselter Zahlungsverkehr auf dieser Website</h5>{' '}
        <p>
          Besteht nach dem Abschluss eines kostenpflichtigen Vertrags eine
          Verpflichtung, uns Ihre Zahlungsdaten (z.&nbsp;B. Kontonummer bei
          Einzugserm&auml;chtigung) zu &uuml;bermitteln, werden diese Daten zur
          Zahlungsabwicklung ben&ouml;tigt.
        </p>
        <p>
          Der Zahlungsverkehr &uuml;ber die g&auml;ngigen Zahlungsmittel
          (Visa/MasterCard, Lastschriftverfahren) erfolgt ausschlie&szlig;lich
          &uuml;ber eine verschl&uuml;sselte SSL- bzw. TLS-Verbindung. Eine
          verschl&uuml;sselte Verbindung erkennen Sie daran, dass die
          Adresszeile des Browsers von &bdquo;http://&ldquo; auf
          &bdquo;https://&ldquo; wechselt und an dem Schloss-Symbol in Ihrer
          Browserzeile.
        </p>{' '}
        <p>
          Bei verschl&uuml;sselter Kommunikation k&ouml;nnen Ihre Zahlungsdaten,
          die Sie an uns &uuml;bermitteln, nicht von Dritten mitgelesen werden.
        </p>
        <h5>Widerspruch gegen Werbe-E-Mails</h5>{' '}
        <p>
          Der Nutzung von im Rahmen der Impressumspflicht ver&ouml;ffentlichten
          Kontaktdaten zur &Uuml;bersendung von nicht ausdr&uuml;cklich
          angeforderter Werbung und Informationsmaterialien wird hiermit
          widersprochen. Die Betreiber der Seiten behalten sich
          ausdr&uuml;cklich rechtliche Schritte im Falle der unverlangten
          Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
        </p>
        <h4>4. Datenerfassung auf dieser Website</h4>
        <h5>Server-Log-Dateien</h5>{' '}
        <p>
          Der Provider der Seiten erhebt und speichert automatisch Informationen
          in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
          &uuml;bermittelt. Dies sind:
        </p>
        <ul>
          <li>Browsertyp und Browserversion</li>
          <li>verwendetes Betriebssystem</li>
          <li>Referrer URL</li>
          <li>Hostname des zugreifenden Rechners</li>
          <li>Uhrzeit der Serveranfrage</li>
          <li>IP-Adresse</li>
        </ul>
        <p>
          Eine Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen wird
          nicht vorgenommen.
        </p>{' '}
        <p>
          Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
          der technisch fehlerfreien Darstellung und der Optimierung seiner
          Website &ndash; hierzu m&uuml;ssen die Server-Log-Files erfasst
          werden.
        </p>
        <h5>Kontaktformular</h5>{' '}
        <p>
          Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
          Angaben aus dem Anfrageformular inklusive der von Ihnen dort
          angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und f&uuml;r
          den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben
          wir nicht ohne Ihre Einwilligung weiter.
        </p>{' '}
        <p>
          Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung eines
          Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung
          vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen
          &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem
          berechtigten Interesse an der effektiven Bearbeitung der an uns
          gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
          Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
          wurde; die Einwilligung ist jederzeit widerrufbar.
        </p>{' '}
        <p>
          Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
          uns, bis Sie uns zur L&ouml;schung auffordern, Ihre Einwilligung zur
          Speicherung widerrufen oder der Zweck f&uuml;r die Datenspeicherung
          entf&auml;llt (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihrer
          Anfrage). Zwingende gesetzliche Bestimmungen &ndash; insbesondere
          Aufbewahrungsfristen &ndash; bleiben unber&uuml;hrt.
        </p>
        <h5>Anfrage per E-Mail, Telefon oder Telefax</h5>{' '}
        <p>
          Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre
          Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten
          (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
          gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre
          Einwilligung weiter.
        </p>{' '}
        <p>
          Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung eines
          Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung
          vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen
          &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem
          berechtigten Interesse an der effektiven Bearbeitung der an uns
          gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
          Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
          wurde; die Einwilligung ist jederzeit widerrufbar.
        </p>{' '}
        <p>
          Die von Ihnen an uns per Kontaktanfragen &uuml;bersandten Daten
          verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern, Ihre
          Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r die
          Datenspeicherung entf&auml;llt (z.&nbsp;B. nach abgeschlossener
          Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen
          &ndash; insbesondere gesetzliche Aufbewahrungsfristen &ndash; bleiben
          unber&uuml;hrt.
        </p>
        <h5>Registrierung auf dieser Website</h5>{' '}
        <p>
          Sie k&ouml;nnen sich auf dieser Website registrieren, um
          zus&auml;tzliche Funktionen auf der Seite zu nutzen. Die dazu
          eingegebenen Daten verwenden wir nur zum Zwecke der Nutzung des
          jeweiligen Angebotes oder Dienstes, f&uuml;r den Sie sich registriert
          haben. Die bei der Registrierung abgefragten Pflichtangaben
          m&uuml;ssen vollst&auml;ndig angegeben werden. Anderenfalls werden wir
          die Registrierung ablehnen.
        </p>{' '}
        <p>
          F&uuml;r wichtige &Auml;nderungen etwa beim Angebotsumfang oder bei
          technisch notwendigen &Auml;nderungen nutzen wir die bei der
          Registrierung angegebene E-Mail-Adresse, um Sie auf diesem Wege zu
          informieren.
        </p>{' '}
        <p>
          Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt
          zum Zwecke der Durchf&uuml;hrung des durch die Registrierung
          begr&uuml;ndeten Nutzungsverh&auml;ltnisses und ggf. zur Anbahnung
          weiterer Vertr&auml;ge (Art. 6 Abs. 1 lit. b DSGVO).
        </p>{' '}
        <p>
          Die bei der Registrierung erfassten Daten werden von uns gespeichert,
          solange Sie auf dieser Website registriert sind und werden
          anschlie&szlig;end gel&ouml;scht. Gesetzliche Aufbewahrungsfristen
          bleiben unber&uuml;hrt.
        </p>
        <h4>5. Soziale Medien</h4>
        <h5>Verbindungen zur Sozialen Medien</h5>{' '}
        <p>
          Die Inhalte auf dieser Website k&ouml;nnen datenschutzkonform in
          sozialen Netzwerken wie Facebook &amp; Co. geteilt werden. Diese Seite
          nutzt daf&uuml;r eigene Schaltflächen. Dieses Schaltflächen stellen
          den direkten Kontakt zwischen den Netzwerken und Nutzern erst dann
          her, wenn der Nutzer aktiv auf einer diese Schaltflächen klickt. Der
          Klick auf den Button stellt eine Einwilligung im Sinne des Art. 6 Abs.
          1 lit. a DSGVO und &sect; 25 Abs. 1 TDDDG dar. Diese Einwilligung kann
          jederzeit mit Wirkung f&uuml;r die Zukunft widerrufen werden.
        </p>{' '}
        <p>
          Eine automatische &Uuml;bertragung von Nutzerdaten an die Betreiber
          dieser Plattformen erfolgt durch diese Schaltflächen nicht. Unsere
          Nutzer k&ouml;nnen die Inhalte dieser Seite datenschutzkonform in
          sozialen Netzwerken teilen, ohne dass komplette Surf-Profile durch die
          Betreiber der Netzwerke erstellt werden.
        </p>{' '}
        <p>Rechtsgrundlage hierf&uuml;r ist Art. 6 Abs. 1 lit. c DSGVO.</p>
        <h4>6. Newsletter</h4>
        <h5>Newsletter&shy;daten</h5>{' '}
        <p>
          Wenn Sie den auf der Website angebotenen Newsletter beziehen
          m&ouml;chten, ben&ouml;tigen wir von Ihnen eine E-Mail-Adresse sowie
          Informationen, welche uns die &Uuml;berpr&uuml;fung gestatten, dass
          Sie der Inhaber der angegebenen E-Mail-Adresse sind und mit dem
          Empfang des Newsletters einverstanden sind. Weitere Daten werden nicht
          bzw. nur auf freiwilliger Basis erhoben. Diese Daten verwenden wir
          ausschlie&szlig;lich f&uuml;r den Versand der angeforderten
          Informationen und geben diese nicht an Dritte weiter.
        </p>{' '}
        <p>
          Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen
          Daten erfolgt ausschlie&szlig;lich auf Grundlage Ihrer Einwilligung
          (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur
          Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum
          Versand des Newsletters k&ouml;nnen Sie jederzeit widerrufen, etwa
          &uuml;ber den &bdquo;Austragen&ldquo;-Link im Newsletter. Die
          Rechtm&auml;&szlig;igkeit der bereits erfolgten
          Datenverarbeitungsvorg&auml;nge bleibt vom Widerruf unber&uuml;hrt.
        </p>{' '}
        <p>
          Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten
          Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter bei
          uns bzw. dem Newsletterdiensteanbieter gespeichert und nach der
          Abbestellung des Newsletters oder nach Zweckfortfall aus der
          Newsletterverteilerliste gel&ouml;scht. Wir behalten uns vor,
          E-Mail-Adressen aus unserem Newsletterverteiler nach eigenem Ermessen
          im Rahmen unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f
          DSGVO zu l&ouml;schen oder zu sperren.
        </p>{' '}
        <p>
          Daten, die zu anderen Zwecken bei uns gespeichert wurden, bleiben
          hiervon unber&uuml;hrt.
        </p>{' '}
        <p>
          Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre
          E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in
          einer Blacklist gespeichert, sofern dies zur Verhinderung
          k&uuml;nftiger Mailings erforderlich ist. Die Daten aus der Blacklist
          werden nur f&uuml;r diesen Zweck verwendet und nicht mit anderen Daten
          zusammengef&uuml;hrt. Dies dient sowohl Ihrem Interesse als auch
          unserem Interesse an der Einhaltung der gesetzlichen Vorgaben beim
          Versand von Newslettern (berechtigtes Interesse im Sinne des Art. 6
          Abs. 1 lit. f DSGVO). Die Speicherung in der Blacklist ist zeitlich
          nicht befristet.{' '}
          <strong>
            Sie k&ouml;nnen der Speicherung widersprechen, sofern Ihre
            Interessen unser berechtigtes Interesse &uuml;berwiegen.
          </strong>
        </p>
        <h4>7. Plugins und Tools</h4>
        <h5>Google Fonts (lokales Hosting)</h5>{' '}
        <p>
          Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
          genannte Google Fonts, die von Google bereitgestellt werden. Die
          Google Fonts sind lokal installiert. Eine Verbindung zu Servern von
          Google findet dabei nicht statt.
        </p>{' '}
        <p>
          Weitere Informationen zu Google Fonts finden Sie unter{' '}
          <a
            href="https://developers.google.com/fonts/faq"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://developers.google.com/fonts/faq
          </a>{' '}
          und in der Datenschutzerkl&auml;rung von Google:{' '}
          <a
            href="https://policies.google.com/privacy?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy?hl=de
          </a>
          .
        </p>
        <h5>Font Awesome (lokales Hosting)</h5>{' '}
        <p>
          Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten Font
          Awesome. Font Awesome ist lokal installiert. Eine Verbindung zu
          Servern von Fonticons, Inc. findet dabei nicht statt.
        </p>{' '}
        <p>
          Weitere Informationen zu Font Awesome finden Sie in der
          Datenschutzerkl&auml;rung f&uuml;r Font Awesome unter:{' '}
          <a
            href="https://fontawesome.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://fontawesome.com/privacy
          </a>
          .
        </p>
        <h4>8. eCommerce und Zahlungs&shy;anbieter</h4>
        <h5>Verarbeiten von Kunden- und Vertragsdaten</h5>{' '}
        <p>
          Wir erheben, verarbeiten und nutzen personenbezogene Kunden- und
          Vertragsdaten zur Begr&uuml;ndung, inhaltlichen Ausgestaltung und
          &Auml;nderung unserer Vertragsbeziehungen. Personenbezogene Daten
          &uuml;ber die Inanspruchnahme dieser Website (Nutzungsdaten) erheben,
          verarbeiten und nutzen wir nur, soweit dies erforderlich ist, um dem
          Nutzer die Inanspruchnahme des Dienstes zu erm&ouml;glichen oder
          abzurechnen. Rechtsgrundlage hierf&uuml;r ist Art. 6 Abs. 1 lit. b
          DSGVO.
        </p>
        <p>
          Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder
          Beendigung der Gesch&auml;ftsbeziehung und Ablauf der ggf. bestehenden
          gesetzlichen Aufbewahrungsfristen gel&ouml;scht. Gesetzliche
          Aufbewahrungsfristen bleiben unber&uuml;hrt.
        </p>
        <h5>
          Daten&shy;&uuml;bermittlung bei Vertragsschluss f&uuml;r
          Dienstleistungen und digitale Inhalte
        </h5>
        <p>
          Wir &uuml;bermitteln personenbezogene Daten an Dritte nur dann, wenn
          dies im Rahmen der Vertragsabwicklung notwendig ist, etwa an das mit
          der Zahlungsabwicklung beauftragte Kreditinstitut.
        </p>{' '}
        <p>
          Eine weitergehende &Uuml;bermittlung der Daten erfolgt nicht bzw. nur
          dann, wenn Sie der &Uuml;bermittlung ausdr&uuml;cklich zugestimmt
          haben. Eine Weitergabe Ihrer Daten an Dritte ohne ausdr&uuml;ckliche
          Einwilligung, etwa zu Zwecken der Werbung, erfolgt nicht.
        </p>{' '}
        <p>
          Grundlage f&uuml;r die Datenverarbeitung ist Art. 6 Abs. 1 lit. b
          DSGVO, der die Verarbeitung von Daten zur Erf&uuml;llung eines
          Vertrags oder vorvertraglicher Ma&szlig;nahmen gestattet.
        </p>
      </div>
      <div id="privacy_policy_en">
        <h1>Privacy Policy</h1>
        <h4>1. An overview of data protection</h4>
        <h5>General information</h5>{' '}
        <p>
          The following information will provide you with an easy to navigate
          overview of what will happen with your personal data when you visit
          this website. The term &ldquo;personal data&rdquo; comprises all data
          that can be used to personally identify you. For detailed information
          about the subject matter of data protection, please consult our Data
          Protection Declaration, which we have included beneath this copy.
        </p>
        <h5>Data recording on this website</h5>{' '}
        <h4>
          Who is the responsible party for the recording of data on this website
          (i.e., the &ldquo;controller&rdquo;)?
        </h4>{' '}
        <p>
          The data on this website is processed by the operator of the website,
          whose contact information is available under section
          &ldquo;Information about the responsible party (referred to as the
          &ldquo;controller&rdquo; in the GDPR)&rdquo; in this Privacy Policy.
        </p>{' '}
        <h4>How do we record your data?</h4>{' '}
        <p>
          We collect your data as a result of your sharing of your data with us.
          This may, for instance be information you enter into our contact form.
        </p>{' '}
        <p>
          Other data shall be recorded by our IT systems automatically or after
          you consent to its recording during your website visit. This data
          comprises primarily technical information (e.g., web browser,
          operating system, or time the site was accessed). This information is
          recorded automatically when you access this website.
        </p>{' '}
        <h4>What are the purposes we use your data for?</h4>
        <p>
          A portion of the information is generated to guarantee the error free
          provision of the website. Other data may be used to analyze your user
          patterns.
        </p>{' '}
        <h4>
          What rights do you have as far as your information is concerned?
        </h4>{' '}
        <p>
          You have the right to receive information about the source,
          recipients, and purposes of your archived personal data at any time
          without having to pay a fee for such disclosures. You also have the
          right to demand that your data are rectified or eradicated. If you
          have consented to data processing, you have the option to revoke this
          consent at any time, which shall affect all future data processing.
          Moreover, you have the right to demand that the processing of your
          data be restricted under certain circumstances. Furthermore, you have
          the right to log a complaint with the competent supervising agency.
        </p>{' '}
        <p>
          Please do not hesitate to contact us at any time if you have questions
          about this or any other data protection related issues.
        </p>
        <h5>Analysis tools and tools provided by third parties</h5>{' '}
        <p>
          There is a possibility that your browsing patterns will be
          statistically analyzed when your visit this website. Such analyses are
          performed primarily with what we refer to as analysis programs.
        </p>{' '}
        <p>
          For detailed information about these analysis programs please consult
          our Data Protection Declaration below.
        </p>
        <h4>2. Hosting and Content Delivery Networks (CDN)</h4>
        <p>
          We are hosting the content of our website at the following provider:
        </p>
        <h5>Amazon Web Services (AWS)</h5>{' '}
        <p>
          The provider is the Amazon Web Services EMEA SARL, 38 Avenue John F.
          Kennedy, 1855 Luxembourg (hereinafter referred to as
          &ldquo;AWS&rdquo;).
        </p>{' '}
        <p>
          When you visit our website, your personal data will be processed on
          AWS servers. This may also result in the transfer of personal data to
          the parent company of AWS in the United States. The transfer of data
          to the US is based on the EU&rsquo;s standard contractual clauses. For
          details please consult:{' '}
          <a
            href="https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/
          </a>
          .
        </p>{' '}
        <p>
          For more information, please see the AWS Data Privacy Policy:{' '}
          <a
            href="https://aws.amazon.com/de/privacy/?nc1=f_pr"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://aws.amazon.com/de/privacy/?nc1=f_pr
          </a>
          .
        </p>{' '}
        <p>
          AWS is used on the basis of Art. 6(1)(f) GDPR. We have a legitimate
          interest in a depiction of our website that is as reliable as
          possible. If appropriate consent has been obtained, the processing is
          carried out exclusively on the basis of Art. 6(1)(a) GDPR and &sect;
          25 (1) TDDDG, insofar the consent includes the storage of cookies or
          the access to information in the user&rsquo;s end device (e.g., device
          fingerprinting) within the meaning of the TDDDG. This consent can be
          revoked at any time.
        </p>
        <p>
          The company is certified in accordance with the &ldquo;EU-US Data
          Privacy Framework&rdquo; (DPF). The DPF is an agreement between the
          European Union and the US, which is intended to ensure compliance with
          European data protection standards for data processing in the US.
          Every company certified under the DPF is obliged to comply with these
          data protection standards. For more information, please contact the
          provider under the following link:{' '}
          <a
            href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active
          </a>
          .
        </p>
        <h4>Data processing</h4>{' '}
        <p>
          We have concluded a data processing agreement (DPA) for the use of the
          above-mentioned service. This is a contract mandated by data privacy
          laws that guarantees that they process personal data of our website
          visitors only based on our instructions and in compliance with the
          GDPR.
        </p>
        <h5>Amazon CloudFront CDN</h5>{' '}
        <p>
          We use the Content Delivery Network Amazon CloudFront CDN. The
          provider is Amazon Web Services EMEA SARL, 38 avenue John F. Kennedy,
          L-1855, Luxembourg (hereinafter referred to as &ldquo;Amazon&rdquo;).
        </p>{' '}
        <p>
          Amazon CloudFront CDN is a globally distributed Content Delivery
          Network. During these transactions, the information transfer between
          your browser and our website is technically routed via the Content
          Delivery Network. This enables us to boost the global availability and
          performance capabilities of our website.
        </p>{' '}
        <p>
          The use of Amazon CloudFront CDN is based on our legitimate interest
          in keeping the presentation of our web services as error free and
          secure as possible (Art. 6(1)(f) GDPR).
        </p>{' '}
        <p>
          The data transfer to the United States is based on the Standard
          Contract Clauses of the EU Commission. You can find the details here:{' '}
          <a
            href="https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/
          </a>
          .
        </p>{' '}
        <p>
          For more information on Amazon CloudFront CDN please follow this link:{' '}
          <a
            href="https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice__German_Translation.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice__German_Translation.pdf
          </a>
          .
        </p>
        <p>
          The company is certified in accordance with the &ldquo;EU-US Data
          Privacy Framework&rdquo; (DPF). The DPF is an agreement between the
          European Union and the US, which is intended to ensure compliance with
          European data protection standards for data processing in the US.
          Every company certified under the DPF is obliged to comply with these
          data protection standards. For more information, please contact the
          provider under the following link:{' '}
          <a
            href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active
          </a>
          .
        </p>
        <h4>3. General information and mandatory information</h4>
        <h5>Data protection</h5>{' '}
        <p>
          The operators of this website and its pages take the protection of
          your personal data very seriously. Hence, we handle your personal data
          as confidential information and in compliance with the statutory data
          protection regulations and this Data Protection Declaration.
        </p>{' '}
        <p>
          Whenever you use this website, a variety of personal information will
          be collected. Personal data comprises data that can be used to
          personally identify you. This Data Protection Declaration explains
          which data we collect as well as the purposes we use this data for. It
          also explains how, and for which purpose the information is collected.
        </p>{' '}
        <p>
          We herewith advise you that the transmission of data via the Internet
          (i.e., through e-mail communications) may be prone to security gaps.
          It is not possible to completely protect data against third-party
          access.
        </p>
        <h5>
          Information about the responsible party (referred to as the
          &ldquo;controller&rdquo; in the GDPR)
        </h5>
        <p>The data processing controller on this website is:</p>{' '}
        <p>
          SoCraTes Deutschland e.V.
          <br />
          Am Hirschanger 1<br />
          90610 Winkelhaid
        </p>
        <p>
          Phone: 09187 7061771
          <br />
          E-mail: info@socrates-conference.de
        </p>
        <p>
          The controller is the natural person or legal entity that
          single-handedly or jointly with others makes decisions as to the
          purposes of and resources for the processing of personal data (e.g.,
          names, e-mail addresses, etc.).
        </p>
        <h5>Storage duration</h5>{' '}
        <p>
          Unless a more specific storage period has been specified in this
          privacy policy, your personal data will remain with us until the
          purpose for which it was collected no longer applies. If you assert a
          justified request for deletion or revoke your consent to data
          processing, your data will be deleted, unless we have other legally
          permissible reasons for storing your personal data (e.g., tax or
          commercial law retention periods); in the latter case, the deletion
          will take place after these reasons cease to apply.
        </p>
        <h5>
          General information on the legal basis for the data processing on this
          website
        </h5>{' '}
        <p>
          If you have consented to data processing, we process your personal
          data on the basis of Art. 6(1)(a) GDPR or Art. 9 (2)(a) GDPR, if
          special categories of data are processed according to Art. 9 (1)
          DSGVO. In the case of explicit consent to the transfer of personal
          data to third countries, the data processing is also based on Art. 49
          (1)(a) GDPR. If you have consented to the storage of cookies or to the
          access to information in your end device (e.g., via device
          fingerprinting), the data processing is additionally based on &sect;
          25 (1) TDDDG. The consent can be revoked at any time. If your data is
          required for the fulfillment of a contract or for the implementation
          of pre-contractual measures, we process your data on the basis of Art.
          6(1)(b) GDPR. Furthermore, if your data is required for the
          fulfillment of a legal obligation, we process it on the basis of Art.
          6(1)(c) GDPR. Furthermore, the data processing may be carried out on
          the basis of our legitimate interest according to Art. 6(1)(f) GDPR.
          Information on the relevant legal basis in each individual case is
          provided in the following paragraphs of this privacy policy.
        </p>
        <h5>Recipients of personal data</h5>{' '}
        <p>
          In the scope of our business activities, we cooperate with various
          external parties. In some cases, this also requires the transfer of
          personal data to these external parties. We only disclose personal
          data to external parties if this is required as part of the
          fulfillment of a contract, if we are legally obligated to do so (e.g.,
          disclosure of data to tax authorities), if we have a legitimate
          interest in the disclosure pursuant to Art. 6 (1)(f) GDPR, or if
          another legal basis permits the disclosure of this data. When using
          processors, we only disclose personal data of our customers on the
          basis of a valid contract on data processing. In the case of joint
          processing, a joint processing agreement is concluded.
        </p>
        <h5>Revocation of your consent to the processing of data</h5>{' '}
        <p>
          A wide range of data processing transactions are possible only subject
          to your express consent. You can also revoke at any time any consent
          you have already given us. This shall be without prejudice to the
          lawfulness of any data collection that occurred prior to your
          revocation.
        </p>
        <h5>
          Right to object to the collection of data in special cases; right to
          object to direct advertising (Art. 21 GDPR)
        </h5>{' '}
        <p>
          IN THE EVENT THAT DATA ARE PROCESSED ON THE BASIS OF ART. 6(1)(E) OR
          (F) GDPR, YOU HAVE THE RIGHT TO AT ANY TIME OBJECT TO THE PROCESSING
          OF YOUR PERSONAL DATA BASED ON GROUNDS ARISING FROM YOUR UNIQUE
          SITUATION. THIS ALSO APPLIES TO ANY PROFILING BASED ON THESE
          PROVISIONS. TO DETERMINE THE LEGAL BASIS, ON WHICH ANY PROCESSING OF
          DATA IS BASED, PLEASE CONSULT THIS DATA PROTECTION DECLARATION. IF YOU
          LOG AN OBJECTION, WE WILL NO LONGER PROCESS YOUR AFFECTED PERSONAL
          DATA, UNLESS WE ARE IN A POSITION TO PRESENT COMPELLING PROTECTION
          WORTHY GROUNDS FOR THE PROCESSING OF YOUR DATA, THAT OUTWEIGH YOUR
          INTERESTS, RIGHTS AND FREEDOMS OR IF THE PURPOSE OF THE PROCESSING IS
          THE CLAIMING, EXERCISING OR DEFENCE OF LEGAL ENTITLEMENTS (OBJECTION
          PURSUANT TO ART. 21(1) GDPR).
        </p>{' '}
        <p>
          IF YOUR PERSONAL DATA IS BEING PROCESSED IN ORDER TO ENGAGE IN DIRECT
          ADVERTISING, YOU HAVE THE RIGHT TO OBJECT TO THE PROCESSING OF YOUR
          AFFECTED PERSONAL DATA FOR THE PURPOSES OF SUCH ADVERTISING AT ANY
          TIME. THIS ALSO APPLIES TO PROFILING TO THE EXTENT THAT IT IS
          AFFILIATED WITH SUCH DIRECT ADVERTISING. IF YOU OBJECT, YOUR PERSONAL
          DATA WILL SUBSEQUENTLY NO LONGER BE USED FOR DIRECT ADVERTISING
          PURPOSES (OBJECTION PURSUANT TO ART. 21(2) GDPR).
        </p>
        <h5>Right to log a complaint with the competent supervisory agency</h5>{' '}
        <p>
          In the event of violations of the GDPR, data subjects are entitled to
          log a complaint with a supervisory agency, in particular in the member
          state where they usually maintain their domicile, place of work or at
          the place where the alleged violation occurred. The right to log a
          complaint is in effect regardless of any other administrative or court
          proceedings available as legal recourses.
        </p>
        <h5>Right to data portability</h5>{' '}
        <p>
          You have the right to have data that we process automatically on the
          basis of your consent or in fulfillment of a contract handed over to
          you or to a third party in a common, machine-readable format. If you
          should demand the direct transfer of the data to another controller,
          this will be done only if it is technically feasible.
        </p>
        <h5>Information about, rectification and eradication of data</h5>{' '}
        <p>
          Within the scope of the applicable statutory provisions, you have the
          right to demand information about your archived personal data, their
          source and recipients as well as the purpose of the processing of your
          data at any time. You may also have a right to have your data
          rectified or eradicated. If you have questions about this subject
          matter or any other questions about personal data, please do not
          hesitate to contact us at any time.
        </p>
        <h5>Right to demand processing restrictions</h5>{' '}
        <p>
          You have the right to demand the imposition of restrictions as far as
          the processing of your personal data is concerned. To do so, you may
          contact us at any time. The right to demand restriction of processing
          applies in the following cases:
        </p>
        <ul>
          <li>
            In the event that you should dispute the correctness of your data
            archived by us, we will usually need some time to verify this claim.
            During the time that this investigation is ongoing, you have the
            right to demand that we restrict the processing of your personal
            data.
          </li>
          <li>
            If the processing of your personal data was/is conducted in an
            unlawful manner, you have the option to demand the restriction of
            the processing of your data instead of demanding the eradication of
            this data.
          </li>
          <li>
            If we do not need your personal data any longer and you need it to
            exercise, defend or claim legal entitlements, you have the right to
            demand the restriction of the processing of your personal data
            instead of its eradication.
          </li>
          <li>
            If you have raised an objection pursuant to Art. 21(1) GDPR, your
            rights and our rights will have to be weighed against each other. As
            long as it has not been determined whose interests prevail, you have
            the right to demand a restriction of the processing of your personal
            data.
          </li>
        </ul>
        <p>
          If you have restricted the processing of your personal data, these
          data &ndash; with the exception of their archiving &ndash; may be
          processed only subject to your consent or to claim, exercise or defend
          legal entitlements or to protect the rights of other natural persons
          or legal entities or for important public interest reasons cited by
          the European Union or a member state of the EU.
        </p>
        <h5>SSL and/or TLS encryption</h5>{' '}
        <p>
          For security reasons and to protect the transmission of confidential
          content, such as purchase orders or inquiries you submit to us as the
          website operator, this website uses either an SSL or a TLS encryption
          program. You can recognize an encrypted connection by checking whether
          the address line of the browser switches from &ldquo;http://&rdquo; to
          &ldquo;https://&rdquo; and also by the appearance of the lock icon in
          the browser line.
        </p>{' '}
        <p>
          If the SSL or TLS encryption is activated, data you transmit to us
          cannot be read by third parties.
        </p>
        <h5>Encrypted payment transactions on this website</h5>{' '}
        <p>
          If you are under an obligation to share your payment information (e.g.
          account number if you give us the authority to debit your bank
          account) with us after you have entered into a fee-based contract with
          us, this information is required to process payments.
        </p>{' '}
        <p>
          Payment transactions using common modes of paying (Visa/MasterCard,
          debit to your bank account) are processed exclusively via encrypted
          SSL or TLS connections. You can recognize an encrypted connection by
          checking whether the address line of the browser switches from
          &ldquo;http://&rdquo; to &ldquo;https://&rdquo; and also by the
          appearance of the lock icon in the browser line.
        </p>{' '}
        <p>
          If the communication with us is encrypted, third parties will not be
          able to read the payment information you share with us.
        </p>
        <h5>Rejection of unsolicited e-mails</h5>{' '}
        <p>
          We herewith object to the use of contact information published in
          conjunction with the mandatory information to be provided in our Site
          Notice to send us promotional and information material that we have
          not expressly requested. The operators of this website and its pages
          reserve the express right to take legal action in the event of the
          unsolicited sending of promotional information, for instance via SPAM
          messages.
        </p>
        <h4>4. Recording of data on this website</h4>
        <h5>Server log files</h5>{' '}
        <p>
          The provider of this website and its pages automatically collects and
          stores information in so-called server log files, which your browser
          communicates to us automatically. The information comprises:
        </p>
        <ul>
          <li>The type and version of browser used</li>
          <li>The used operating system</li>
          <li>Referrer URL</li>
          <li>The hostname of the accessing computer</li>
          <li>The time of the server inquiry</li>
          <li>The IP address</li>
        </ul>
        <p>This data is not merged with other data sources.</p>{' '}
        <p>
          This data is recorded on the basis of Art. 6(1)(f) GDPR. The operator
          of the website has a legitimate interest in the technically error free
          depiction and the optimization of the operator&rsquo;s website. In
          order to achieve this, server log files must be recorded.
        </p>
        <h5>Contact form</h5>{' '}
        <p>
          If you submit inquiries to us via our contact form, the information
          provided in the contact form as well as any contact information
          provided therein will be stored by us in order to handle your inquiry
          and in the event that we have further questions. We will not share
          this information without your consent.
        </p>{' '}
        <p>
          The processing of these data is based on Art. 6(1)(b) GDPR, if your
          request is related to the execution of a contract or if it is
          necessary to carry out pre-contractual measures. In all other cases
          the processing is based on our legitimate interest in the effective
          processing of the requests addressed to us (Art. 6(1)(f) GDPR) or on
          your agreement (Art. 6(1)(a) GDPR) if this has been requested; the
          consent can be revoked at any time.
        </p>{' '}
        <p>
          The information you have entered into the contact form shall remain
          with us until you ask us to eradicate the data, revoke your consent to
          the archiving of data or if the purpose for which the information is
          being archived no longer exists (e.g., after we have concluded our
          response to your inquiry). This shall be without prejudice to any
          mandatory legal provisions, in particular retention periods.
        </p>
        <h5>Request by e-mail, telephone, or fax</h5>{' '}
        <p>
          If you contact us by e-mail, telephone or fax, your request, including
          all resulting personal data (name, request) will be stored and
          processed by us for the purpose of processing your request. We do not
          pass these data on without your consent.
        </p>{' '}
        <p>
          These data are processed on the basis of Art. 6(1)(b) GDPR if your
          inquiry is related to the fulfillment of a contract or is required for
          the performance of pre-contractual measures. In all other cases, the
          data are processed on the basis of our legitimate interest in the
          effective handling of inquiries submitted to us (Art. 6(1)(f) GDPR) or
          on the basis of your consent (Art. 6(1)(a) GDPR) if it has been
          obtained; the consent can be revoked at any time.
        </p>
        <p>
          The data sent by you to us via contact requests remain with us until
          you request us to delete, revoke your consent to the storage or the
          purpose for the data storage lapses (e.g. after completion of your
          request). Mandatory statutory provisions - in particular statutory
          retention periods - remain unaffected.
        </p>
        <h5>Registration on this website</h5>{' '}
        <p>
          You have the option to register on this website to be able to use
          additional website functions. We shall use the data you enter only for
          the purpose of using the respective offer or service you have
          registered for. The required information we request at the time of
          registration must be entered in full. Otherwise, we shall reject the
          registration.
        </p>{' '}
        <p>
          To notify you of any important changes to the scope of our portfolio
          or in the event of technical modifications, we shall use the e-mail
          address provided during the registration process.
        </p>{' '}
        <p>
          We shall process the data entered during the registration process on
          the basis of your consent (Art. 6(1)(a) GDPR).
        </p>{' '}
        <p>
          The data recorded during the registration process shall be stored by
          us as long as you are registered on this website. Subsequently, such
          data shall be deleted. This shall be without prejudice to mandatory
          statutory retention obligations.
        </p>
        <h4>5. Social media</h4>
        <h5>Connections to social media</h5>{' '}
        <p>
          The content on this website can be shared in compliance with data
          protection be shared on social networks such as Facebook &amp; Co.
          This page uses its own buttons for this purpose. These buttons
          represent the direct contact between the networks and users only when
          the user actively when the user actively clicks on one of these
          buttons. The click on the button constitutes consent within the
          meaning of Art. 6 para. 1 lit. a GDPR and &sect; 25 para. 1 TDDDG.
          This consent can be revoked be revoked at any time with effect for the
          future.
        </p>{' '}
        <p>
          An automatic transfer of user data to the operators of these platforms
          is of these platforms is not carried out by these buttons. Our users
          can share the content of this page in social networks in compliance
          with data protection networks without complete surfing profiles being
          created by the network created by the operators of the networks.
        </p>{' '}
        <p>The legal basis for this is Art. 6 para. 1 lit. c GDPR.</p>
        <h4>6. Newsletter</h4>
        <h5>Newsletter data</h5>{' '}
        <p>
          If you would like to subscribe to the newsletter offered on this
          website, we will need from you an e-mail address as well as
          information that allow us to verify that you are the owner of the
          e-mail address provided and consent to the receipt of the newsletter.
          No further data shall be collected or shall be collected only on a
          voluntary basis. We shall use such data only for the sending of the
          requested information and shall not share such data with any third
          parties.
        </p>{' '}
        <p>
          The processing of the information entered into the newsletter
          subscription form shall occur exclusively on the basis of your consent
          (Art. 6(1)(a) GDPR). You may revoke the consent you have given to the
          archiving of data, the e-mail address, and the use of this information
          for the sending of the newsletter at any time, for instance by
          clicking on the &ldquo;Unsubscribe&rdquo; link in the newsletter. This
          shall be without prejudice to the lawfulness of any data processing
          transactions that have taken place to date.
        </p>{' '}
        <p>
          The data deposited with us for the purpose of subscribing to the
          newsletter will be stored by us until you unsubscribe from the
          newsletter or the newsletter service provider and deleted from the
          newsletter distribution list after you unsubscribe from the newsletter
          or after the purpose has ceased to apply. We reserve the right to
          delete or block e-mail addresses from our newsletter distribution list
          at our own discretion within the scope of our legitimate interest in
          accordance with Art. 6(1)(f) GDPR.
        </p>{' '}
        <p>Data stored for other purposes with us remain unaffected.</p>{' '}
        <p>
          After you unsubscribe from the newsletter distribution list, your
          e-mail address may be stored by us or the newsletter service provider
          in a blacklist, if such action is necessary to prevent future
          mailings. The data from the blacklist is used only for this purpose
          and not merged with other data. This serves both your interest and our
          interest in complying with the legal requirements when sending
          newsletters (legitimate interest within the meaning of Art. 6(1)(f)
          GDPR). The storage in the blacklist is indefinite.{' '}
          <strong>
            You may object to the storage if your interests outweigh our
            legitimate interest.
          </strong>
        </p>
        <h4>7. Plug-ins and Tools</h4>
        <h5>Google Fonts (local embedding)</h5>{' '}
        <p>
          This website uses so-called Google Fonts provided by Google to ensure
          the uniform use of fonts on this site. These Google fonts are locally
          installed so that a connection to Google&rsquo;s servers will not be
          established in conjunction with this application.
        </p>{' '}
        <p>
          For more information on Google Fonts, please follow this link:{' '}
          <a
            href="https://developers.google.com/fonts/faq"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://developers.google.com/fonts/faq
          </a>{' '}
          and consult Google&rsquo;s Data Privacy Declaration under:{' '}
          <a
            href="https://policies.google.com/privacy?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy?hl=en
          </a>
          .
        </p>
        <h5>Font Awesome (local embedding)</h5>{' '}
        <p>
          This website uses Font Awesome to ensure the uniform use of fonts on
          this site. Font Awesome is locally installed so that a connection to
          Fonticons, Inc.&rsquo;s servers will not be established in conjunction
          with this application.
        </p>{' '}
        <p>
          For more information on Font Awesome, please and consult the Data
          Privacy Declaration for Font Awesome under:{' '}
          <a
            href="https://fontawesome.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://fontawesome.com/privacy
          </a>
          .
        </p>
        <h4>8. eCommerce and payment service providers</h4>
        <h5>Processing of Customer and Contract Data</h5>{' '}
        <p>
          We collect, process, and use personal customer and contract data for
          the establishment, content arrangement and modification of our
          contractual relationships. Data with personal references to the use of
          this website (usage data) will be collected, processed, and used only
          if this is necessary to enable the user to use our services or
          required for billing purposes. The legal basis for these processes is
          Art. 6(1)(b) GDPR.
        </p>{' '}
        <p>
          The collected customer data shall be deleted upon completion of the
          order or termination of the business relationship and upon expiration
          of any existing statutory archiving periods. This shall be without
          prejudice to any statutory archiving periods.
        </p>
        <h5>
          Data transfer upon closing of contracts for services and digital
          content
        </h5>{' '}
        <p>
          We share personal data with third parties only if this is necessary in
          conjunction with the handling of the contract; for instance, with the
          financial institution tasked with the processing of payments.
        </p>{' '}
        <p>
          Any further transfer of data shall not occur or shall only occur if
          you have expressly consented to the transfer. Any sharing of your data
          with third parties in the absence of your express consent, for
          instance for advertising purposes, shall not occur.
        </p>
        <p>
          The basis for the processing of data is Art. 6(1)(b) GDPR, which
          permits the processing of data for the fulfilment of a contract or for
          pre-contractual actions.
        </p>
      </div>
    </div>
  );
}
