import React, { useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './values.scss';
import { LogoColumn } from '../common/LogoColumn';

const cocAnchor = '#coc';

export default function Values() {
  const cocElement = useRef<HTMLAnchorElement>(null);

  const { hash } = useLocation();

  useEffect(() => {
    if (cocElement.current && hash === cocAnchor) {
      cocElement.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [hash]);

  return (
    <div id="values" className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="page-header">
            <h1>The Values of SoCraTes</h1>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="page-header">
                <h2>Inclusiveness</h2>
              </div>
              <p>
                A goal of SoCraTes conference is to be inclusive and welcoming
                to attendees with the most varied and diverse backgrounds
                possible. See <Link to="/accessibility">accessibility</Link> for
                the related details.
              </p>
              <p>
                We loathe the war in Ukraine and strongly object to any form of
                violence and oppression against a civil population. However, we
                also want to be inclusive, and therefore we do not exclude
                people based on their country of origin. While we stand with the
                people of Ukraine, we will not accept harassment of Russian
                citizens solely based on their nationality.
              </p>
              <div className="page-header">
                <a
                  href={cocAnchor}
                  ref={cocElement}
                  aria-label="Code of Conduct"
                >
                  <span aria-hidden="true">#</span>
                </a>
                <h2>Code of Conduct</h2>
              </div>
              <p>
                Please read our full code of conduct before buying a ticket,
                discussing online and before coming to the conference. But{' '}
                <strong>please don’t feel intimidated by it</strong>&mdash;these
                are simple rules, and they will make life better for everyone at
                the conference.
              </p>

              <div className="segment-header">
                <h3>Why we have a CoC</h3>
              </div>
              <p>
                We are dedicated to create a conference where everybody can
                learn, teach, share, network and have a good time. This can only
                work if we are inclusive to the largest number of contributors,
                and if we create an environment, where everybody feels safe and
                welcome.
              </p>
              <p>
                <strong>Yes, we value discussion and disagreement.</strong> And
                discussion can become heated. But there have to be rules, and
                there has to be a red line.
              </p>
              <p>
                In this code of conduct, we lay out those rules and red lines.
              </p>

              <div className="segment-header">
                <h3>Safe Environment</h3>
              </div>
              <p>
                We are committed to providing a friendly, safe and welcoming
                environment for all, regardless of gender, sexual orientation,
                programming language, ability, ethnicity, socioeconomic status,
                and religion (or lack thereof).
              </p>
              <p>
                And so we invite all those who participate in SoCraTes, and the
                community surrounding the conference, to help us create safe and
                positive experiences for everyone. With your help, this
                conference can be a great experience for everyone!
              </p>
              <ul>
                <li>
                  <strong>Treat everyone professionally.</strong> Everybody at
                  the conference is a professional in their field. Treat all
                  attendees as equals. Ask before you teach. Do not explain
                  things without being asked. The person you are talking to
                  right now might know more, or different things about the topic
                  than you!
                </li>
                <li>
                  <strong>Be welcoming, friendly, and patient.</strong> Give
                  people the benefit of the doubt. Ask questions before jumping
                  to conclusions.
                </li>
                <li>
                  <strong>Be respectful. </strong>Not all of us will agree with
                  each other all the time, but disagreement is no excuse for
                  poor behavior and poor manners. We might all experience some
                  frustration now and then, but we cannot allow that frustration
                  to turn into a personal attack.
                </li>
                <li>
                  <strong>
                    Be aware of the effect your words may have on others.
                  </strong>{' '}
                  We are a community of professionals, and we conduct ourselves
                  professionally. Be kind to others. Do not insult or put down
                  other participants. Harassment and other exclusionary behavior
                  aren&#39;t acceptable.
                </li>
                <li>
                  Whenever possible, use inclusive language: For example, say
                  &quot;folks&quot; or &quot;friends&quot; instead of
                  &quot;guys&quot;, and be mindful that some people prefer
                  different pronouns than those you would assume.
                  <br />
                  If you misspeak, don&#39;t feel bad - just apologize and do
                  better next time.
                </li>
                <li>
                  <strong>Be careful with jokes.</strong> We do not tolerate any
                  CoC violations, even if “it was just a joke”.
                </li>
                <li>
                  <strong>Admit when you do not know something.</strong>{' '}
                  Encourage others to admit when they do not know
                  something&mdash;and never joke about it. We are all here to
                  learn.
                </li>
              </ul>
              <div className="segment-header">
                <h3>CoC Violations</h3>
              </div>
              <p>
                If you think someone has violated our code of conduct&mdash;even
                if you were not directly involved, like you just overheard a
                conversation&mdash;please:
              </p>
              <ul>
                <li>
                  Let the person know that what they did is not appropriate and
                  ask them to stop.
                </li>
                <li>
                  Contact the organisers of SoCraTes (See contact details
                  below).
                </li>
              </ul>
              <p>
                But please give people the benefit of doubt. If there is even a
                slight chance that this was a misunderstanding (e.g. the person
                did not speak in their native language, and did not find the
                right words), try to sort it out in a friendly, constructive
                way.
              </p>
              <p>
                When we learn about a CoC violations, organisers will hear both
                sides, and then take action we deem appropriate, such as:
              </p>
              <ul>
                <li>Give a warning</li>
                <li>Have a longer talk about our values</li>
                <li>
                  Expel the perpetrator from the conference without a refund
                  (requires two organisers to agree, if this was the first
                  offence)
                </li>
                <li>Call the authorities</li>
              </ul>
              <div className="segment-header">
                <h3>Unacceptable Behaviour</h3>
              </div>
              <p>Unacceptable behaviour includes, but is not limited to:</p>
              <ul>
                <li>
                  Harassment, and other exclusionary behaviour. Deliberate
                  intimidation and threats.
                </li>
                <li>
                  Aggressive or sexualized language and content. Unwanted sexual
                  advances.
                </li>
                <li>Insulting or putting down other participants.</li>
                <li>
                  Publishing or telling others, that a participant belongs to a
                  particular identity channel without asking their consent
                  first.
                </li>
                <li>
                  “Well-actuallies”: Telling people what they “actually meant to
                  say”.
                </li>
                <li>
                  Other conduct which could reasonably be considered
                  inappropriate in a professional setting.
                </li>
              </ul>
              <div className="segment-header">
                <h3>Need Help</h3>
              </div>
              <p>
                If you need help, have any further questions or have any other
                concerns, please contact a member of conference staff
                immediately.
              </p>
              <ul>
                <li>Find and talk to the organisers</li>
                <li>
                  Contact{' '}
                  <a href="mailto:info@socrates-conference.de">
                    info@socrates-conference.de
                  </a>
                </li>
                <li>Ask in the channel #help on Discord</li>
                <li>Contact an organiser on Discord</li>
              </ul>
              <p>
                This code of conduct applies to the SoCraTes conference itself,
                and to all digital spaces that are related to the SoCraTes
                conference, such as the wiki and mailing list.
              </p>
              <div className="page-header">
                <h3>Credits</h3>
              </div>
              <p>These values are inspired by the following works.</p>
              <ul>
                <li>
                  <a
                    href="https://socrates-conference.at/code-of-conduct/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    SoCraTes Days Linz Code of Conduct{' '}
                  </a>
                </li>
                <li>
                  <a
                    href="https://wealljs.org/code-of-conduct"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    WeAllJS Code of Conduct{' '}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.djangoproject.com/conduct/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    Django Code of Conduct{' '}
                  </a>
                </li>
                <li>
                  <a
                    href="http://citizencodeofconduct.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    Citizenship Code of Conduct{' '}
                  </a>
                </li>
              </ul>
              <div className="segment-header">
                <h3>Further Questions?</h3>
              </div>
              <div>
                <p>Feel invited to contact us via one of the buttons below.</p>
              </div>
            </div>
            <LogoColumn />
          </div>
        </div>
      </div>
    </div>
  );
}
