import React from 'react';
import { LogoColumn } from '../common/LogoColumn';
export default function Accessibility() {
  return (
    <div id="accessibility" className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="page-header">
            <h1>Accessibility</h1>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <div className="page-header">
            <h3>Accessibility</h3>
          </div>
          <div>
            <p>
              A primary goal of SoCraTes conference is to be inclusive and
              foster an environment where all of our attendees, with their
              varied and diverse backgrounds, can feel at ease. SoCraTes is
              committed to offering as much accessibility and comfort to
              disabled people, minorities and marginalised groups as possible.
              Our conference venue is Hotel Park Soltau. All conference rooms
              are on the same floor with step-free access. We have accessible
              toilets, gender neutral toilets, car parking and are only a short
              way from the train station. We are aware that people have a
              diverse range of needs due to neurodiversity, disability, chronic
              illness or impairment. We will work with the venue to try and meet
              your needs, so that you have the possibility to be a part of this
              great open conference.{' '}
            </p>
            <p>
              If you have questions or specific needs, please{' '}
              <strong>contact</strong> our diversity spokesperson at{' '}
              <a href="mailto:accessibility@socrates-conference.de">
                accessibility@socrates-conference.de
              </a>
              . They&#39;ll be available online and in person at the conference.
            </p>
          </div>
          <dl>
            <dt>Venue</dt>
            <dd>
              Conference rooms are on the same floor with step-free access and
              there are no long ways between them. The hotel has two wheelchair
              accessible rooms. The conference rooms and bedrooms are in the
              same location and can be reached quickly. We ask all participants
              to make sure there is enough time between the sessions to reach
              the other rooms.
            </dd>
            <dt>Seating</dt>
            <dd>
              We see that some people will need access to a seat, or a seat in a
              particular location. We will provide priority seating in the
              conference rooms. Those seats will be in multiple locations near
              the doors, on the aisles or in the front of the room. The seats
              will be marked and we will ask participants to make them available
              to you. If you need a priority seat and wish to have a sticker
              indicating that, we will give you one at registration. We will
              also leave space for wheelchairs.
            </dd>
            <dt>Queuing</dt>
            <dd>
              We understand queuing can be difficult, painful or impossible for
              some participants. We do not anticipate long queues during the
              conference, but will make participants aware to let people skip
              the line if necessary.
            </dd>
            <dt>Toilets</dt>
            <dd>
              There will be gender neutral and wheelchair accessible toilets
              near the conference rooms. These can be used by anyone.
            </dd>
            <dt>Food and drinks</dt>
            <dd>
              Water, tea, coffee and snacks are served throughout the day at the
              conference location. Three meals a day with vegan and vegetarian
              options are served in the cafeteria near the conference rooms. If
              you have dietary needs, you can tell us when we collect your
              booking information and we will work with the venue to accommodate
              them. The food buffet tables are not wheelchair accessible. We
              will help with getting food if necessary.
            </dd>
            <dt>Quiet areas</dt>
            <dd>
              We understand that the social interactions and noisiness of a
              conference can be taxing. There are multiple quiet corners in the
              conference area, both inside and outside. If you need to take a
              longer break, the bedrooms are very near to the conference rooms.
            </dd>
            <dt>Wheelchair accessibility</dt>
            <dd>
              The hotel has two wheelchair accessible single rooms, including
              wheelchair accessible baths. If you require one of these rooms,
              indicate so when we collect your personal data after you won a
              seat in the lottery. The doors both of the conference and bedrooms
              are 120 cm wide. There is a wheelchair accessible toilet near the
              conference rooms. The buffet tables are not wheelchair accessible,
              but we will help wheelchair users to access the food. The venue is
              accessible step-free from the nearby train station Soltau Nord and
              has plenty of parking space.
            </dd>
            <dt>Assistance persons</dt>
            <dd>
              If you need an assistance person, we will try to help with the
              attendance costs of the assistant. When you fill in your personal
              data, indicate the name of the assistance person and we will
              register them separately.
            </dd>
            <dt>Assistance animals</dt>
            <dd>
              Assistance animals are welcome at the venue. The hotel has three
              rooms that are suitable for dogs, one single, one double and one
              that can be either double or shared. The assistance animal has to
              stay with the assisted person at all times.
            </dd>
            <dt>Deaf attendants</dt>
            <dd>
              If you need a sign language interpreter, please send an email to{' '}
              <a href="mailto:accessibility@socrates-conference.de">
                accessibility@socrates-conference.de
              </a>{' '}
              with information on what sign language you need and also indicate
              so in the registration. We will do our best to find and hire one.
              If you already know a person that would work for you, let us know
              their contact information.
            </dd>
            <dt>Blind and sight impaired attendants</dt>
            <dd>
              Assistance animals are welcome at the venue (see above). The
              conference program will be made available in a screen-reader
              compatible format during the conference. Due to the spontaneous
              nature of an open space conference, we cannot make it available
              before the conference. We will try to update any changes as soon
              as possible.
            </dd>
            <dt>People with photosensitive epilepsy/seizures</dt>
            <dd>
              We will ask participants to indicate if their presentation
              contains strobe effects or flickering.
            </dd>
          </dl>
        </div>
        <LogoColumn />
      </div>
    </div>
  );
}
